.bd-equal-width {
  width: 14.28%;
  text-align: center;
}
.align-item-center {
  align-items: center;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.css-1nmdiq5-menu {
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.bd-custom-md {
  max-width: 400px !important;
}
.bd-custom-md-2 {
  max-width: 550px !important;
}
.bd-show-product {
  max-width: 750px !important;
  margin-left: 31.3%;
}
.bd-add-product {
  max-width: 500px !important;
}
.custom-container {
  max-width: 98% !important;
}
.bd-custom-side-nav {
  width: 20% !important ;
}
.bd-custom-tab-content {
  width: 80% !important;
}
.display-none {
  display: none;
}
.bd-incremental-input {
  width: 31%;
}
.bd-incremental-input-delete {
  width: 5%;
}
.bio-edit-btn {
  width: 35%;
  /* margin-bottom:10px */
}
.id-width {
  width: 8%;
}
.bd-overlay-img {
  background-image: url("../../images/small/login-back-img.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.main-login-page {
  padding-top: 120px !important;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35);
}
.action-width {
  width: 15%;
}
.product-width {
  width: 11.5%;
}

.btn-theme-color {
  background-color: #e7b413;
  border: none;
  color: black;
  font-weight: 500;
  padding: 8px 16px;
}
.btn-theme-color:hover {
  background-color: #e7b413;
  color: black;
}
.btn-theme-color:active {
  background-color: #e7b413 !important;
  color: black !important;
}
.staff-action-width {
  width: 10%;
}
.staff-id-width {
  width: 5%;
}
.bd-custom-sidebar {
  padding: 4px 7px;
  font-size: 10px;
}
.filter-sidebar {
  z-index: 1;
  background-color: white;
  overflow-x: hidden;
  margin-top: 20px;
  padding: 10px 20px;
  transition: all 0.5s ease-in-out;
}

.sidebar-nav-master {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 70px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.bd-whole-navber {
  padding: 20px;
}
.bd-pass-icon {
  position: absolute;
  top: 58%;
  right: 15px;
}
.border-bottom-color {
  border-bottom: 1px solid black;
}
.action-width-role {
  width: 10%;
}
.border-radius-btn {
  border-radius: 0.25rem !important;
}
.bd-custom-md-4 {
  max-width: 700px !important;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}
.form-check-input {
  background-color: #adadada6;
  border: none;
}

.form-check-input:checked {
  background-color: #556ee6;
  border-color: #556ee6;
  border: none;
}
.flex-grow-12 {
  padding: 13px 0px;
  border-radius: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-left: 20px;
  width: 150px;
}
.flex-grow-12 h4 {
  font-size: 18px;
}
.mb-12 {
  margin-bottom: 12px;
}
.bd-text-logo {
  padding: 15px;
  color: white;
  background-color: #b6b5b5;
  border-radius: 50%;
  font-size: 20px;
  width: 100%;
  display: inline-block;
  text-align: center;
  border: 5px solid var(--bs-gray-300);
}
.bd-text-logo-profile {
  padding: 30px 35px;
  color: white;
  background-color: #b6b5b5;
  border-radius: 50%;
  font-size: 28px;
  display: inline-block;
  text-align: center;
  border: 5px solid var(--bs-gray-300);
}

.bd-header-logo-img {
  color: white;
  background-color: #b6b5b5;
  border-radius: 50%;
  font-size: 13.5px;
  width: 38px;
  height: 38px;
  display: inline-block;
  line-height: 36px;
  padding-left: 1px;
}
.bd-label-profile {
  color: black;
  font-weight: 600;
  font-size: 15px;
}
.font-15 {
  font-size: 15px;
}
.img-logo-text {
  line-height: 30px;
  font-size: 20px;
  color: white;
  font-weight: 800;
}
.vertical-collpsed .img-logo-text {
  display: none;
}
.vertical-collpsed .list-unstyled {
  margin-top: 0px !important;
}

.fixed-table-header {
  max-height: 320px;
  scrollbar-width: thin;
}

.fixed-table-header tr th {
  position: sticky;
  top: -0.5px;
  right: 0;
  left: 0;
  box-shadow: 0 2px 2px -1px #00000055;
  z-index: 1;
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .bd-responsive-btn {
    width: 100% !important;
    text-align: center !important;
  }
  .bd-incremental-input {
    width: 100%;
  }
  .bd-custom-tab-content {
    width: 100% !important;
  }
  .bd-custom-side-nav {
    width: 100% !important;
  }
  .bio-edit-btn {
    /* width: 100% !important; */
    /* margin-bottom: 10px; */
  }

  .staff-id-width {
    width: 100%;
  }
  .staff-action-width {
    width: 100%;
  }
  .id-width {
    width: 100%;
  }
  .action-width {
    width: 100%;
  }

  .full-width {
    width: 100% !important;
  }
  .product-width {
    width: 100% !important;
  }
  .bd-side-header {
    margin-top: -48px;
    width: 60%;
  }
  .bd-side-nav-master {
    position: relative;
    z-index: 999;
  }
}
@media screen and (min-width: 320px) and (max-width: 700px) {
  .nav-responsive {
    display: none;
  }
  .bd-text-center {
    text-align: center;
  }
  .resposive-d-flex {
    flex-wrap: wrap;
  }
  .no-margin-left {
    margin-left: 0px !important;
    margin-top: 10px;
  }
  .bd-responsive-filter {
    display: none;
  }
  .border-botttom-black {
    border-bottom: 1px solid var(--bs-table-color);
  }

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  .responsive-main-table {
    margin-top: 15px;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  /*
  tr {
    border: 1px solid black;
  } */

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 51% !important;
    /* padding-top:8px !important;
    padding-bottom:8px !important */
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 12px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* Label the data */
    content: attr(data-column);
    color: var(--bs-table-color);
    font-weight: bold;
  }
  .view-modal-height {
    max-height: 520px;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
  .bio-edit-btn {
    width: 27%;
  }
  .d-mobile-none {
    display: none;
  }
  .action-width-role {
    width: 100%;
  }
  .resp-mr-10 {
    margin-right: 10px;
  }
  .flex-grow-12 {
    width: 100px;
    padding: 13px 10px !important;
    margin-left: 10px;
  }
  .bd-resp-num {
    font-size: 14px !important;
  }
  .bd-resp-back {
    background-color: #cedafe;
  }
  .bd-resp-back2 {
    background-color: rgba(244, 106, 106, 0.18);
  }
  .bd-resp-back3 {
    background-color: rgba(52, 195, 143, 0.18);
  }
}
@media screen and (min-width: 700px) {
  .Nav-dropdown-resp {
    display: none;
  }
  .bd-responsive-filter2 {
    display: none;
  }
  .bd-edit-resposnive-btn {
    width: 40% !important;
  }
}
@media screen and (max-width: 700px) {
  .bd-show-extra-field {
    display: none !important;
  }
}
.padding-table td {
  padding: 10px;
  text-align: center;
}
.bd-import-target {
  max-width: 350px;
}

.padding-table th {
  padding: 10px;
}
.bd-target-show {
  max-width: 710px !important;
}
/* .swal-modal {
  width: 350px;
}
.swal-icon {
  width: 60px;
  height: 60px;
}
.swal-icon--warning__body {
  height: 30px;
} */
/* .swal-title{
  padding: 0px;
} */
.mt-08 {
  margin-top: 8px;
}
.bd-categories {
  padding: 5px 15px;

  border-radius: 5px;
  margin-left: 10px;
}
.bd-categories.active {
  background-color: #556ee6;
  color: white;
}
/* .bd-categories:hover {
  color: white;
} */
